import { storyblokEditable } from '@storyblok/react/rsc'
import { clsx } from 'clsx'
import { type FC, useMemo } from 'react'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokFlexType } from '../../model/storyblokTypes.generated'
import { StoryblokComponentsRenderer } from '../StoryblokComponentsRenderer'

import styles from './StoryblokFlex.module.css'

const getChildrenFlexClasses = (blok: StoryblokFlexType): string => {
  return clsx(
    blok.mobileChildrenFlex === '1' &&
      styles['flex-children-get-same-space__mobile'],
    blok.tabletChildrenFlex === '1' &&
      styles['flex-children-get-same-space__tablet'],
    blok.desktopChildrenFlex === '1' && styles['flex-children-get-same-space'],
  )
}

const getFlexDirection = (blok: StoryblokFlexType): string => {
  return clsx(
    blok.mobileFlexDirection === 'column' && 'flex-col',
    blok.tabletFlexDirection === 'column' && 'tablet:flex-col',
    blok.desktopFlexDirection === 'column' && 'desktop:flex-col',

    blok.mobileFlexDirection === 'row' && 'flex-row',
    blok.tabletFlexDirection === 'row' && 'tablet:flex-row',
    blok.desktopFlexDirection === 'row' && 'desktop:flex-row',
  )
}

const getGap = (blok: StoryblokFlexType): string => {
  return clsx(
    blok.mobileGap === 'Content/Medium' && 'gap-4',
    blok.tabletGap === 'Content/Medium' && 'tablet:gap-4',
    blok.desktopGap === 'Content/Medium' && 'desktop:gap-4',

    blok.mobileGap === 'Content/Large' && 'gap-6',
    blok.tabletGap === 'Content/Large' && 'tablet:gap-6',
    blok.desktopGap === 'Content/Large' && 'desktop:gap-6',

    blok.mobileGap === 'Content/Extra Large' && 'gap-9',
    blok.tabletGap === 'Content/Extra Large' && 'tablet:gap-9',
    blok.desktopGap === 'Content/Extra Large' && 'desktop:gap-9',
  )
}

const getFlexWrap = (blok: StoryblokFlexType): string => {
  return clsx(
    blok.mobileFlexWrap === 'wrap' && 'flex-wrap',
    blok.tabletFlexWrap === 'wrap' && 'tablet:flex-wrap',
    blok.desktopFlexWrap === 'wrap' && 'desktop:flex-wrap',

    blok.mobileFlexWrap === 'nowrap' && 'flex-nowrap',
    blok.tabletFlexWrap === 'nowrap' && 'tablet:flex-nowrap',
    blok.desktopFlexWrap === 'nowrap' && 'desktop:flex-nowrap',
  )
}

const getAlignItems = (blok: StoryblokFlexType): string => {
  return clsx(
    blok.mobileAlignItems === 'flex-start' && 'items-start',
    blok.mobileAlignItems === 'flex-end' && 'items-end',
    blok.mobileAlignItems === 'center' && 'items-center',

    blok.tabletAlignItems === 'flex-start' && 'tablet:items-start',
    blok.tabletAlignItems === 'flex-end' && 'tablet:items-end',
    blok.tabletAlignItems === 'center' && 'tablet:items-center',

    blok.desktopAlignItems === 'flex-start' && 'desktop:items-start',
    blok.desktopAlignItems === 'flex-end' && 'desktop:items-end',
    blok.desktopAlignItems === 'center' && 'desktop:items-center',
  )
}

const getJustifyContent = (blok: StoryblokFlexType): string => {
  return clsx(
    blok.mobileJustifyContent === 'flex-start' && 'justify-start',
    blok.mobileJustifyContent === 'flex-end' && 'justify-end',
    blok.mobileJustifyContent === 'center' && 'justify-center',

    blok.tabletJustifyContent === 'flex-start' && 'tablet:justify-start',
    blok.tabletJustifyContent === 'flex-end' && 'tablet:justify-end',
    blok.tabletJustifyContent === 'center' && 'tablet:justify-center',

    blok.desktopJustifyContent === 'flex-start' && 'desktop:justify-start',
    blok.desktopJustifyContent === 'flex-end' && 'desktop:justify-end',
    blok.desktopJustifyContent === 'center' && 'desktop:justify-center',
  )
}

const getFlex = (blok: StoryblokFlexType): string => {
  return clsx(
    blok.mobileFlex === '1' && 'flex-1',
    blok.tabletFlex === '1' && 'tablet:flex-1',
    blok.desktopFlex === '1' && 'desktop:flex-1',
  )
}

export const useClassNames = (blok: StoryblokFlexType): string => {
  return useMemo((): string => {
    return clsx(
      'flex',
      getChildrenFlexClasses(blok),
      getFlex(blok),
      getFlexDirection(blok),
      getGap(blok),
      getFlexWrap(blok),
      getAlignItems(blok),
      getJustifyContent(blok),
    )
  }, [blok])
}

export const StoryblokFlex: FC<StoryblokComponentProps<StoryblokFlexType>> = ({
  blok,
}) => {
  const classNames = useClassNames(blok)

  return (
    <div {...storyblokEditable(blok)} className={classNames}>
      <StoryblokComponentsRenderer bloks={blok.content} />
    </div>
  )
}
